import React from 'react';

const HuntingByPermission = () => {
  const heroImage = '/public/images/hunting.jpg';
  return (
    <div className="hunting page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>Hunting by Permission</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <h2>Hunting by Permission</h2>
            <p>
              Passes for hunting on private land, within the dykes of Richmond are now available through the RRGC. Your
              pass must be signed by the Landowner, A Landowner can only sign for the property they own, Not the
              property they lease. Please call in advance. There is a new format and it may take time to process your
              application.
            </p>
            <p>In order to obtain a Hunting By Permission Pass, you must provide the following. </p>
            <p>
              <ol>
                <li>1. FISH and Wildlife ID Number (FWID)</li>
                <li>Valid Resident Hunting Licence Number (RHC)</li>
                <li>
                  Fraser Valley Special Area Hunting License Number with proof of valid $5,000,000 Public Liability and Property Damage, valid until March 31, 2022.
                </li>
                <li>Migratory Game Bird Hunting Permit</li>
                <li>Current address, telephone number, and e-mail address</li>
                <li>Name and BC Drivers Licence Number</li>
                <li>Make, Model, Colour, Licence Plate Number of vehicle</li>
                <li>Address of farm(s) you intend to hunt</li>
              </ol>
            </p>
            <h2>To obtain a pass contact:</h2>
            <p>
              Evan (Richmond Rod &Gun Club, Hunting by Permission Chairman)
              <br />
              Phone <a href="tel:778-865-3826">778-865-3826</a>
              <br />
              Email <a href="mailto:evan_le_gal@hotmail.com">evan_le_gal@hotmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HuntingByPermission;
