import React from 'react';
import AntiBot, { openTo } from '../../components/AntiBot';
import { triggerDownload } from '../../services/firebase';

const IndoorProvincials = () => {
  const heroImage = '/public/images/hall-min.jpg';

  const phone = ['7', '3', '0', '3', '.', '2', '7', '2', '.', '4', '0', '6'];
  return (
    <div className="archery page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>Info Coming Soon!</h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="introduction-block">
          <div className="left-block">
            <div className="location-block">
              <a href="https://goo.gl/maps/RGBLF6XJJPGDRzA89" target="_blank">
                <div className="location-icon-container">
                  <i className="fa fa-map-marker-alt"></i>
                </div>
                <div className="location-info-container">
                  <p>
                    <span className="heading">TBD</span>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div className="right-content">
            <div className="introduction-text">
              <h2>TBD</h2>
              <p>
                TBD{' '}
              </p>
            </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default IndoorProvincials;
