import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const onNavigate = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="footer-container">
      <div className="footer-inner-container">
        <div className="footer-links">
          <h2>RRGC</h2>
          <ul className="links-list">
            <li>
              <Link to="/membership" onClick={onNavigate}>
                Membership Policies
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={onNavigate}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-social-media-links">
          <h2>Stay Connected</h2>
          <a href="https://www.facebook.com/RRGCArchery/" target="_blank" aria-label="RRGC Archery Facebook">
            <i className="fa fa-facebook-official" aria-hidden="true" />
          </a>
          <a href="https://www.instagram.com/rrgc.archery" target="_blank" aria-label="RRGC Archery Instagram">
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>
        </div>
        <div className="footer-copyright">
          <i className="fa fa-copyright" aria-hidden="true" />
          <span>&nbsp;2020 All Rights Reserved Richmond Rod and Gun Club</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
