import React, { useState } from "react";
import firebase from "../../services/firebase";
import moment from "moment";
import AntiBot, { openTo } from "../../components/AntiBot";
import Axios from "axios";

const NOT_SENT = "NOT_SENT";
const SENDING = "SENDING";
const SENT = "SENT";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [heroImage] = useState("/public/images/contact-min.jpg");
  const [status, setStatus] = useState(NOT_SENT);
  const [error, setError] = useState(false);

  const contact = [
    "m",
    "o",
    "c",
    ".",
    "b",
    "u",
    "l",
    "c",
    "n",
    "u",
    "g",
    "d",
    "n",
    "a",
    "d",
    "o",
    "r",
    "d",
    "n",
    "o",
    "m",
    "h",
    "c",
    "i",
    "r",
    "@",
    "s",
    "u",
    "t",
    "c",
    "a",
    "t",
    "n",
    "o",
    "c",
  ];

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.currentTarget.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      email,
      message,
      subject: "RRGC Website: General Inquiry",
      time: moment().format("LLL"),
    };

    setStatus(SENDING);
    Axios.post("https://us-central1-rrgc-a26aa.cloudfunctions.net/submit", data)
      .then(() => {
        setEmail("");
        setMessage("");
        setStatus(SENT);
      })
      .catch((error) => {
        setError(true);
      });
  };

  return (
    <div className="page">
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
      <div className="contact-us container">
        <h2>Send us a message</h2>
      </div>
      <div className="contact-us container">
        {error ? (
          <div className="error-box">
            <p>
              Unforunately, our contact form is experiencing unforeseen issues.
              You can still reach us at{" "}
              <a href="javascript:void(0)" onClick={openTo(contact, " mailto")}>
                <AntiBot text={contact} />
              </a>
              .
            </p>
            <p>We look forward to hearing from you!</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <label for="email">E-mail:</label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="johndoe@gmail.com"
              required
            />
            <label for="message">Message</label>
            <textarea
              type="text"
              name="message"
              value={message}
              onChange={handleMessageChange}
              placeholder="Your message here!"
            />
            <br />
            <input
              type="submit"
              value={
                status === SENT
                  ? "Sent!"
                  : status === SENDING
                  ? "Sending..."
                  : "Send"
              }
              disabled={status === SENT || status === SENDING}
              required
            />
          </form>
        )}
      </div>
    </div>
  );
};

export default Contact;
