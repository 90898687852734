import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => {
  const [navbar, setNavbar] = useState(false);
  const toggleNavbar = () => {
    setNavbar(!navbar);
  };
  const onNavigate = () => {
    setNavbar(false);
    window.scrollTo(0, 0);
  };
  return (
    <div className="navbar-container">
      <div className="navbar-inner-container">
        <div className="navbar-logo">
          <a href="/">
            <h1 className="logo-placeholder">R R G C</h1>
          </a>
        </div>
        <div className="navbar-hamburger">
          <button onClick={toggleNavbar}>
            {navbar ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
          </button>
        </div>
        <div className={`navbar-links ${navbar ? 'mobile-active' : ''}`}>
          <NavLink exact={true} to="/" onClick={onNavigate}>
            Home
          </NavLink>
          <NavLink to="/archery" onClick={onNavigate}>
            Archery
          </NavLink>
          <NavLink to="/indoorprovincials" onClick={onNavigate}>
            Indoor Provincials
          </NavLink>
          <NavLink to="/airgun" onClick={onNavigate}>
            Airgun
          </NavLink>
          <NavLink to="/hall" onClick={onNavigate}>
            Hall
          </NavLink>
          <NavLink to="/hunting-by-permission" onClick={onNavigate}>
            Hunting
          </NavLink>
          <NavLink to="/membership" onClick={onNavigate}>
            Membership
          </NavLink>
          <NavLink to="/contact" onClick={onNavigate}>
            Contact Us
          </NavLink>
          <a href="https://www.facebook.com/RRGCArchery/" target="_blank" aria-label="RRGC Archery Facebook">
            <i className="fa fa-facebook-official" aria-hidden="true" />
          </a>
          <a href="https://www.instagram.com/rrgc.archery" target="_blank" aria-label="RRGC Archery Instagram">
            <i className="fa fa-instagram" aria-hidden="true" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
