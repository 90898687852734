import React from 'react';

/* expects a reversed array of text */
const AntiBot = ({ text }) => {
  return (
    <span className="ab">
      {text.map((c) => {
        return (
          <span>
            <span>{c}</span>
            <span style={{ display: 'none' }}>x</span>
          </span>
        );
      })}
    </span>
  );
};

const openTo = (arr, to) => () => {
  window.open(`${to}:${arr.reverse().join('')}`, '_blank');
};

export { openTo };

export default AntiBot;
