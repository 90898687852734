import React, { useState, useEffect, useRef } from 'react';
import { DATE_KEY_FORMAT, ARCHERY_HOURS, MAX_ARCHERY_ARCHERS, MAX_ARCHERY_SPECTATORS } from '../../services/constants';
import moment from 'moment';

const VAR_MAX = {
  archers: MAX_ARCHERY_ARCHERS,
  spectators: MAX_ARCHERY_SPECTATORS
};

const RegistrationComponent = (props) => {
  const [currentBookings, setCurrentBookings] = useState({});
  const [selectionData, setSelectionData] = useState({});
  const [selectedAddons, setSelectedAddons] = useState({});
  const [userCart, setUserCart] = useState({ dates: [], addons: [] });
  const [week, setWeek] = useState(moment().week());
  const [page, setPage] = useState(0);

  const [addons, setAddons] = useState({
    target_face_80: {
      name: 'Archery Target Face (80cm)',
      image: '/public/images/80.jpg',
      price: 4
    },
    target_face_60: {
      name: 'Archery Target Face (60cm)',
      image: '/public/images/80.jpg',
      price: 1
    },
    target_face_40: {
      name: 'Archery Target Face (40cm)',
      image: '/public/images/80.jpg',
      price: 1
    },
    target_face_3v: {
      name: 'Archery Target Face (3-Spot Vertical)',
      image: '/public/images/3v.jpg',
      price: 1
    },
    target_face_3t: {
      name: 'Archery Target Face (3-Spot Triangle)',
      image: '/public/images/3tri.jpg',
      price: 1
    }
  });

  const nextPage = () => {
    setPage(Math.min(page + 1, pages.length));
  };

  const prevPage = () => {
    setPage(Math.max(page - 1, 0));
  };

  const checkout = () => {
    console.log(userCart);
  };

  const pages = [
    { next: nextPage, nextText: 'Next: Add-Ons' },
    { next: checkout, nextText: 'Checkout', prev: prevPage, prevText: 'Back' }
  ];

  const getDate = (day) => {
    return moment()
      .day(day)
      .week(week);
  };

  const setPreviousWeek = () => {
    if (week - 1 >= moment().week()) {
      setWeek(week - 1);
    }
  };

  const setCurrentWeek = () => {
    setWeek(moment().week());
  };

  const setNextWeek = () => {
    setWeek(week + 1);
  };

  let saveTimeout, saveTimeout1;

  const saveAddons = (sa) => {
    setSelectedAddons({ ...sa });
    if (saveTimeout) {
      clearTimeout(saveTimeout1);
    }
    saveTimeout = setTimeout(() => {
      localStorage.setItem('selectedAddons', JSON.stringify(selectedAddons));
    }, 100);
  };

  const saveSD = (sd) => {
    setSelectionData({ ...sd });
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    saveTimeout = setTimeout(() => {
      localStorage.setItem('selectionData', JSON.stringify(selectionData));
    }, 100);
  };

  const signUp = (date, section, slot) => () => {
    const sd = selectionData;
    sd[date] = sd[date] || {};
    sd[date][section] = sd[date][section] || Array(VAR_MAX[section]).map(() => false);
    sd[date][section][slot] = !sd[date][section][slot];
    saveSD(sd);
  };

  const clearSelection = () => {
    setSelectedAddons({});
    setSelectionData({});
    localStorage.removeItem('selectionData');
    localStorage.removeItem('selectedAddons');
  };

  const clearDate = (date) => () => {
    const sd = selectionData;
    delete sd[date];
    saveSD(sd);
  };

  const clearAddon = (addonKey) => () => {
    const sa = selectedAddons;
    delete sa[addonKey];
    saveAddons(sa);
  };

  const calculateSubtotal = () => {
    return userCart.addons.reduce(
      (cost, item) => {
        return cost + item.price * item.quantity;
      },
      userCart.dates.reduce((cost, item) => {
        return cost + item.archers * 5 + item.spectators * 0;
      }, 0)
    );
  };

  const countArchers = (dateString) => {
    return ((selectionData[dateString] || {}).archers || []).reduce((count, selected) => {
      if (selected) {
        count++;
      }
      return count;
    }, 0);
  };

  const modifyQuantity = (addon, val) => () => {
    const sa = selectedAddons[addon] || {};
    selectedAddons[addon] = {
      price: addons[addon].price,
      quantity: Math.max((sa.quantity || 0) + val, 0)
    };
    saveAddons(selectedAddons);
  };

  useEffect(() => {
    const savedCartString = localStorage.getItem('selectionData');
    const savedAddonsString = localStorage.getItem('selectedAddons');
    let savedCart, savedAddons;
    try {
      savedCart = JSON.parse(savedCartString);
      savedAddons = JSON.parse(savedAddonsString);
    } catch (e) {}
    if (savedCart) {
      setSelectionData(savedCart);
    }
    setTimeout(() => {
      setSelectedAddons(savedAddons);
    });
    if (savedAddons) {
    }
  }, []);

  useEffect(() => {
    const ucDates = [];
    Object.keys(selectionData).map((date) => {
      const archers = countArchers(date);
      const spectators = ((selectionData[date] || {}).spectators || []).reduce((count, selected) => {
        if (selected) {
          count++;
        }
        return count;
      }, 0);
      if (archers > 0 || spectators > 0) {
        ucDates.push({
          date,
          mdate: moment(date),
          archers,
          spectators
        });
      }
    });

    ucDates.sort((a, b) => {
      if (moment(a.date).isBefore(b.date)) {
        return -1;
      }
      return 0;
    });

    console.log(ucDates);
    setUserCart({
      ...userCart,
      dates: ucDates
    });
  }, [selectionData]);

  useEffect(() => {
    const ucAddons = [];
    Object.keys(selectedAddons).map((addon) => {
      const sa = selectedAddons[addon];
      const tally = sa.price * sa.quantity;
      if (sa.quantity > 0) {
        ucAddons.push({
          id: addon,
          name: addons[addon].name,
          tally: tally,
          ...sa
        });
      }
    });

    setUserCart({
      ...userCart,
      addons: ucAddons
    });
  }, [selectedAddons]);

  useEffect(() => {
    /* Connect to firebase */
    // ARCHERY_HOURS.map((day) => {
    //   const date = getDate(day.label);
    //   const dateKey = date.format(DATE_KEY_FORMAT);

    //   if (!currentBookings[dateKey]) {
    //     const rootRef = firebase.database().ref(`${BOOKINGS_REF}/${dateKey}`);
    //     rootRef.on('value', (snapshot) => {
    //       currentBookings[dateKey] = snapshot.val();
    //       setCurrentBookings({ ...currentBookings });
    //     });
    //   }
    // });
  }, [week]);

  return (
    <div className="registration-container">
      {page === 0 && (
        <div className="registration-component">
          <h2>Dates Selection</h2>
          <div className="calendar-controls">
            <button onClick={setPreviousWeek}>
              <i className="fas fa-caret-left"></i>
            </button>
            <button onClick={setCurrentWeek}>Current Week</button>
            <button onClick={setNextWeek}>
              <i className="fas fa-caret-right"></i>
            </button>
          </div>
          <div className="registration-week">
            {ARCHERY_HOURS.map((day) => {
              const date = getDate(day.label);
              const dateKey = date.format(DATE_KEY_FORMAT);
              const dateString = date.format('ll');
              const archeryFilled = Object.keys((currentBookings[dateKey] || {}).general || {}).reduce(
                (count, bookingId) => {
                  if (currentBookings[dateKey].general[bookingId].active) {
                    return count + 1;
                  }
                  return count;
                },
                0
              );
              const spectatorsFilled = Object.keys((currentBookings[dateKey] || {}).spectators || {}).reduce(
                (count, bookingId) => {
                  if (currentBookings[dateKey].spectators[bookingId].active) {
                    return count + 1;
                  }
                  return count;
                },
                0
              );
              const slotsAvailable = MAX_ARCHERY_ARCHERS - archeryFilled;
              const spectatorsAvailable = MAX_ARCHERY_SPECTATORS - spectatorsFilled;
              const isAfter = date.isSameOrAfter(moment(), 'day');
              const isToday = date.isSame(moment(), 'day');
              return (
                <div className={`registration-day ${day.isOpen && isAfter ? '' : 'closed'}`}>
                  {isToday && <div className="today-marker">Today</div>}
                  <div className="date-label-container">
                    <div className="day-label">
                      <h3>{day.label}</h3>
                    </div>
                    <div className="date-label">{dateString}</div>
                    <div className="time-label">{day.hours}</div>
                  </div>
                  {day.isOpen && (
                    <div className="timeslots">
                      <div className="archers">
                        <div className="section-label">General Archer</div>
                        <div className="timeslot-list">
                          {[...Array(slotsAvailable)].map((_blank, slot) => {
                            const isSelected =
                              selectionData[dateString] && (selectionData[dateString].archers || {})[slot];
                            return (
                              <button
                                className={`timeslot ${isSelected ? 'selected' : ''}`}
                                onClick={signUp(dateString, 'archers', slot)}
                                disabled={!day.isOpen || !isAfter}>
                                {isSelected ? 'Selected' : 'Available'}
                              </button>
                            );
                          })}
                          {[...Array(archeryFilled)].map(() => {
                            return <div className="timeslot filled">Filled</div>;
                          })}
                        </div>
                      </div>
                      <div className="spectators">
                        <div className="section-label">Spectators</div>
                        <div className="timeslot-list">
                          {[...Array(spectatorsAvailable)].map((_blank, slot) => {
                            const isSelected =
                              selectionData[dateString] && (selectionData[dateString].spectators || {})[slot];
                            return (
                              <button
                                className={`timeslot ${isSelected ? 'selected' : ''}`}
                                onClick={signUp(dateString, 'spectators', slot)}
                                disabled={!day.isOpen || !isAfter}>
                                {isSelected ? 'Selected' : 'Available'}
                              </button>
                            );
                          })}
                          {[...Array(spectatorsFilled)].map(() => {
                            return <div className="timeslot filled">Filled</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {page === 1 && (
        <div className="addons">
          <h2>Add-Ons (Optional)</h2>
          <div className="addons-list">
            {Object.keys(addons).map((addonKey) => {
              const addon = addons[addonKey];
              return (
                <div className="addon">
                  <div className="addon-select">
                    <h4>{addon.name}</h4>
                  </div>
                  <div className="addon-image" style={{ backgroundImage: `url(${addon.image})` }}></div>
                  <div className="addon-quantity">
                    <button onClick={modifyQuantity(addonKey, -1)}>-</button>
                    <div className="addon-quantity-value">{(selectedAddons[addonKey] || {}).quantity || 0}</div>
                    <button onClick={modifyQuantity(addonKey, 1)}>+</button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <div className="user-cart">
        <h2 className="user-cart-heading">Summary</h2>
        <div className="summary-item-list">
          {userCart.dates.length > 0 ? (
            userCart.dates.map((date) => {
              return (
                <div className="summary-item">
                  <div className="summary-date-label">
                    <div className="summary-dow">{date.mdate.format('dddd')}</div>
                    <div className="summary-date">{date.mdate.format('MMMM Do, YYYY')}</div>
                    <a href="javascript:void(0)" className="summary-remove-date" onClick={clearDate(date.date)}>
                      <i className="fas fa-times"></i>
                    </a>
                  </div>
                  <div className="summary-item-contents">
                    {date.archers > 0 && (
                      <div className="summary-item-section">
                        <div className="summary-item-label">
                          {date.archers} General Archer{date.archers > 1 ? 's' : ''}
                        </div>
                        <div className="summary-item-subtotal">${date.archers * 5}</div>
                      </div>
                    )}
                    {date.spectators > 0 && (
                      <div className="summary-item-section">
                        <div className="summary-item-label">
                          {date.spectators} Spectators{date.spectators > 1 ? 's' : ''}
                        </div>
                        <div className="summary-item-subtotal">${date.archers * 0}</div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <p className="no-dates">No Dates Selected</p>
          )}
        </div>
        <div className="summary-item-list">
          {userCart.addons.map((addon) => {
            return (
              <div className="summary-item">
                <div className="summary-date-label">
                  <div className="summary-dow">{addon.name}</div>
                  <a href="javascript:void(0)" className="summary-remove-date" onClick={clearAddon(addon.id)}>
                    <i className="fas fa-times"></i>
                  </a>
                </div>
                <div className="summary-item-contents">
                  <div className="summary-item-section">
                    <div className="summary-item-label">
                      ${addon.price} x {addon.quantity}
                    </div>
                    <div className="summary-item-subtotal">${addon.tally}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="summary-subtotal">${calculateSubtotal()}</div>
        <div className="checkout">
          {pages[page].prev && (
            <a href="javascript:void(0)" onClick={pages[page].prev} className="checkout-button">
              {pages[page].prevText}
            </a>
          )}
          {pages[page].next && (
            <a href="javascript:void(0)" onClick={pages[page].next} className="checkout-button">
              {pages[page].nextText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationComponent;
