import React, { useState } from "react";
import ReactGA from "react-ga";
import { triggerDownload } from "../../services/firebase";

const KIDSPORT_URL =
  "https://kidsportcanada.ca/british-columbia/provincial-fund/";

const Membership = () => {
  const heroImage = "/public/images/membership-min.jpg";

  const handleMembershipDownload = () => {
    ReactGA.event({
      category: "Membership",
      action: "Downloaded Membership Form",
    });
    triggerDownload("public/Membership Application 2024-2025.pdf");
  };

  return (
    <div className="membership page">
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="page-banner-text">
          <div className="is-operational"></div>
        </div>
      </div>
      <section>
        <div className="container">
          <h2>Membership</h2>
          <p>
            Richmond Rod and Gun Club provides its members access to both the
            Archery and Airgun Range at a very low cost, as well as other club
            events such as trap shooting and photography competitions.
          </p>
          <div className="benefit-box">
            <div className="benefits">
              <i className="fas fa-dollar-sign"></i>
              <div className="text">
                Exclusive Members-Only Pricing for our indoor ranges
              </div>
            </div>
            <div className="benefits">
              <i className="fas fa-calendar-check"></i>
              <div className="text">Club Events, socials and get-togethers</div>
            </div>
            <div className="benefits">
              <i className="fas fa-vote-yea"></i>
              <div className="text">
                Attend the AGM and participate in the future of RRGC
              </div>
            </div>
            <div className="benefits">
              <i className="fas fa-users"></i>
              <div className="text">Family Memberships Available</div>
            </div>
          </div>
          <h3>Join Today!</h3>
          <p>
            Our registration process has been streamlined to allow easier
            membership applications.
            <ol>
              <li>
                Download the{" "}
                <a href="javascript:void(0)" onClick={handleMembershipDownload}>
                  <span>RRGC 2024-2025 Membership Form</span>
                </a>
              </li>
              <li>
                Fill out the form, and send the form to{" "}
                <a href="mailto:membership@richmondrodandgunclub.com">
                  membership@richmondrodandgunclub.com
                </a>
              </li>
              <li>
                E-transfer membership application fee to{" "}
                <a href="mailto:membership@richmondrodandgunclub.com">
                  membership@richmondrodandgunclub.com
                </a>
              </li>
            </ol>
          </p>
          <p>
            Alternatively, you can also mail the membership application form and{" "}
            <strong>cheque (not cash)</strong> to:
          </p>
          <p style={{ paddingLeft: "18px" }}>
            <strong>
              P.O. Box 26551 Blundell Center,
              <br />
              Richmond, BC,
              <br />
              V7C 5M9
            </strong>
          </p>
          <br />
          <p>
            <a
              className="membership-form-download"
              href="javascript:void(0)"
              onClick={handleMembershipDownload}
            >
              <i className="fas fa-file-contract"></i>
              <span>RRGC 2024-2025 Membership Form Download</span>
            </a>
          </p>
        </div>

        <div className="container generic-benefits">
          <h2>Richmond Rod and Gun Club is more than a club. </h2>
          <p>
            We are a community of like-minded individuals who strive for
            discipline, excellence and self-improvement in their sport and
            practice great sportsmanship and respect for their peers, mentors,
            mentees and adversaries alike.
          </p>
        </div>

        <section className="membership container">
          <div className="membership-card">
            <h3>Non-member</h3>
            <h1>$15</h1>
            <p>Limited Access to Club Facilities</p>
            <div className="benefits">
              <p>Full Range Fees</p>
              <p>Limited to 3 visits to the Airgun Range</p>
            </div>
          </div>
          <div className="membership-card gold">
            <h3>Annual Membership</h3>
            <h1>$80¹</h1>
            <p>For all individuals</p>
            <div className="benefits">
              <p>
                <i className="fas fa-check"></i> Exclusive Prices on range fees
              </p>
              <p>
                <i className="fas fa-check"></i> No limits on number of visits
              </p>
              <p>
                <i className="fas fa-check"></i> Access to Archery Club Workshop
              </p>
              <p>
                <i className="fas fa-check"></i> Trap & Skeet shooting events
              </p>
              <p>
                <i className="fas fa-check"></i> Club Photography Competition
              </p>
              <p>
                <i className="fas fa-check"></i> Club Socials and Private Events
              </p>
            </div>
            <i className="bg-icon fas fa-star"></i>
          </div>
          <div className="membership-card gold">
            <KidsportEligible />
            <h3>Annual Family Membership</h3>
            <h1>$110¹</h1>
            <p>For the whole family</p>
            <div className="benefits">
              <p>
                <i className="fas fa-check"></i> All benefits of Annual
                Membership
              </p>
              <p>
                Includes membership and benefits for:
                <br />
                - Spouse
                <br />- All legal dependants under 18 years of age
              </p>
              <p>
                Applicant's dependants may be eligible for a{" "}
                <b>
                  <a href={KIDSPORT_URL} target="_blank">
                    Kidsport Grant
                  </a>
                </b>
                .
              </p>
            </div>
            <i className="bg-icon fas fa-users"></i>
          </div>
        </section>
        <section className="membership container">
          <div className="membership-card gold">
            <KidsportEligible />
            <h3>
              Annual
              <br />
              Junior Membership
            </h3>
            <h1>$50¹</h1>
            <p>18 years and younger only</p>
            <div className="benefits">
              <p>
                <i className="fas fa-check"></i> All benefits of Annual
                Membership
              </p>
              <p>Only available to persons 18 years and younger.</p>
              <p>
                Applicants may be eligible for a{" "}
                <b>
                  <a href={KIDSPORT_URL} target="_blank">
                    Kidsport Grant
                  </a>
                </b>
                .
              </p>
            </div>
            <i className="bg-icon fas fa-child"></i>
            <div className="label special">Special</div>
          </div>
          <div className="membership-card gold">
            <h3>
              Annual
              <br />
              Senior Membership
            </h3>
            <h1>$45¹</h1>
            <p>65 years and better only</p>
            <div className="benefits">
              <p>
                <i className="fas fa-check"></i> All benefits of Annual
                Membership
              </p>
              <p>Only available to persons 65 years and better.</p>
            </div>
            <i className="bg-icon fas fa-user"></i>
            <div className="label special">Special</div>
          </div>
        </section>
        <section className="container">
          <h2 className="kidsport-title">
            Kidsport Grant Eligible
            <img src="/public/images/kidsport.png" />
          </h2>
          <p>
            Archery and Aigun are Kidsport-elligible sports in BC. Some families
            may be elligible to receive grants that can go towards memberships
            and coaching! Visit{" "}
            <a href={KIDSPORT_URL} target="_blank">
              {KIDSPORT_URL}
            </a>{" "}
            for more information.
          </p>
        </section>
        <section className="container">
          <h2>Additional Fees - BC Wildlife Federation</h2>
          <p>
            BC Wildlife Federation Membership and Insurance is mandatory for our
            membership. These prices are subject to change by BCWF, and will be
            added to the membership fee from Richmond Rod and Gun Club upon
            payment.
          </p>
          <div className="bcwf-container">
            <div className="bcwf">
              <h3>Single Membership</h3>
              <p>
                $27.00 Membership <br /> $5.25 Insurance
              </p>
            </div>
            <div className="bcwf">
              <h3>Junior Membership</h3>
              <p>
                $16.50 Membership <br /> $5.25 Insurance
              </p>
            </div>
            <div className="bcwf">
              <h3>Senior Membership</h3>
              <p>
                $22.00 Membership <br /> $5.25 Insurance
              </p>
            </div>
            <div className="bcwf">
              <h3>Family Membership</h3>
              <p>
                $36.50 Membership <br /> $10.50 Insurance
              </p>
            </div>
          </div>
        </section>

        <section className="container">
          <h2 className="text-center">Join Today!</h2>

          <p>
            Our registration process has been streamlined to allow easier
            membership applications.
            <ol>
              <li>
                Download the{" "}
                <a href="javascript:void(0)" onClick={handleMembershipDownload}>
                  <span>
                    RRGC 2024-2025 Membership Renewal / Application Form
                  </span>
                </a>
              </li>
              <li>
                Fill out the form, and send the form to{" "}
                <a href="mailto:membership@richmondrodandgunclub.com">
                  membership@richmondrodandgunclub.com
                </a>
              </li>
              <li>
                E-transfer membership application fee to{" "}
                <a href="mailto:membership@richmondrodandgunclub.com">
                  membership@richmondrodandgunclub.com
                </a>
              </li>
            </ol>
          </p>
          <p>
            Alternatively, you can also mail the membership application form and{" "}
            <strong>cheque (not cash)</strong> to:
          </p>
          <p style={{ paddingLeft: "18px" }}>
            <strong>
              P.O. Box 26551 Blundell Center,
              <br />
              Richmond, BC,
              <br />
              V7C 5M9
            </strong>
          </p>
          <br />
          <p>
            <a
              className="membership-form-download"
              href="javascript:void(0)"
              onClick={handleMembershipDownload}
            >
              <i className="fas fa-file-contract"></i>
              <span>
                RRGC 2024-2025 Membership Application / Renewal Form Download
              </span>
            </a>
          </p>
        </section>
      </section>
    </div>
  );
};

const KidsportEligible = () => (
  <div className="kidsport-eligible">
    <div className="kidsport-eligible-image"></div>
  </div>
);
export default Membership;
