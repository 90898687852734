import React from "react";
import { Link } from "react-router-dom";

const KIDSPORT_URL =
  "https://kidsportcanada.ca/british-columbia/provincial-fund/";

const heroImage = "/public/images/hero-min.jpg";
const airgunImage = "/public/images/airgun-min.jpg";
const archeryImage = "/public/images/archery-min.jpg";
const fishingImage = "/public/images/fishing-min.jpg";
const huntingImage = "/public/images/hunting.jpg";
const photographyImage = "/public/images/photography-min.jpg";

const Home = () => {
  const breakingNews = "";
  return (
    <div className="home page">
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="page-banner-text">
          <div className="page-banner-text-container">
            <p>Richmond Rod and Gun Club</p>
            <h1>One membership, multiple possibilities</h1>
            <Link className="cta-button" to="/membership">
              Learn more
            </Link>
          </div>
        </div>
      </div>
      {breakingNews && (
        <section className="breaking-news positive">
          <div className="container">
            <h3>
              <a href="/archery">
                {breakingNews} {"  "}
                <i className="fas fa-caret-right"></i>
              </a>
            </h3>
          </div>
        </section>
      )}
      <section className="announcements covid">
        <div className="container">
          <h2>Welcome!</h2>
          <p>
            Welcome to the Richmond Rod and Gun Club or RRGC for short! 
            At our facility we offer both archery and airgun for anyone interested!
            Our club offers rental equipment for those just starting out, or if you have your own equipment you're more than welcome to bring it and come shoot!
          </p>
          <p>
            We offer hall rentals if you require large space to host events or banquets.
          </p>
          <p>
            Our membership runs from April 1 - March 31 which you can find more information <Link className="cta-link" to="/membership">here</Link>.
            As a bonus, our membership includes a BC Wildlife Federation membership and insurance.
            A membership here not only includes a discount on archery and airgun drop in rates, but exclusive invites to club events!
          </p>
        </div>
      </section>

      <section className="landing-division">
        <div className="division-container container right">
          <div className="division-content">
            <div className="division-content-inner">
              <h2>Archery</h2>
              <div className="division-details">
                <p>
                  Located in Richmond, BC, our 18m indoor archery range provides
                  a safe, welcoming location for both beginner and experienced
                  archers.
                </p>
                <p>
                  Our diverse shooting community sees people from a wide range
                  of disciplines, including compound, olympic recurve and
                  traditional shooters.
                </p>
              </div>
              <div className="division-cta-container">
                <Link className="cta-link" to="/archery">
                  Learn more about archery
                </Link>
              </div>
            </div>
          </div>
          <div
            className="division-prop"
            style={{ backgroundImage: `url(${archeryImage})` }}
          ></div>
        </div>
      </section>

      <section className="landing-division">
        <div className="division-container container">
          <div
            className="division-prop"
            style={{ backgroundImage: `url(${airgunImage})` }}
          ></div>
          <div className="division-content">
            <div className="division-content-inner">
              <h2>Airgun</h2>
              <div className="division-details">
                <p>
                  Focused on Olympic 10m Air Pistol and Air Rifle shooting
                  discipline, our 10m ten-position indoor range at Richmond, BC
                  welcomes both new and experienced shooters.
                </p>
                <p>
                  Our main goal is to support and encourage an understanding and
                  respect of marksmanship and its sportsmen.
                </p>
              </div>
              <div className="division-cta-container">
                <Link className="cta-link" to="/airgun">
                  Learn more about Airgun
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="letter">
        <div className="container">
          <div>
            <h2 className="text-center kidsport-title">
              Kidsport Grant Eligible
              <img src="/public/images/kidsport.png" />
            </h2>
          </div>
          <div>
            <p>
              Archery and Aigun are Kidsport-elligible sports in BC. Some
              families may be elligible to receive grants that can go towards
              memberships and coaching! Visit
              {" "}
              <Link className="cta-link" to={KIDSPORT_URL} target="_blank">
                {KIDSPORT_URL}
              </Link>
              {" "}
              for more information.
            </p>
          </div>
        </div>
      </section>

      <section className="community">
        <div className="container">
          <h2 className="text-center">
            Join a enthusiastic community of experienced hobbyists
          </h2>
          <div className="division-cards">
            <div className="division-card">
              <div
                className="card-image"
                style={{ backgroundImage: `url(${fishingImage})` }}
              ></div>
              <div className="card-content">
                <h2>Fishing</h2>
                <div className="division-card-content">
                  <p>
                    Practice safe and sustainable reacreational fishing with
                    fellow members of RRGC.
                  </p>
                </div>
              </div>
            </div>
            <div className="division-card">
              <div
                className="card-image"
                style={{ backgroundImage: `url(${huntingImage})` }}
              ></div>
              <div className="card-content">
                <h2>Hunting</h2>
                <div className="division-card-content">
                  <p>
                    Engage in safe, legal and sustainable hunting practices in
                    the best of nature B.C. has to offer.
                  </p>
                </div>
              </div>
            </div>
            <div className="division-card">
              <div
                className="card-image"
                style={{
                  backgroundImage: `url(${photographyImage})`,
                }}
              ></div>
              <div className="card-content">
                <h2>Photography</h2>
                <div className="division-card-content">
                  <p>
                    Not all shooting sports must include projectiles. Share your
                    best moments and pride in capturing the beauty that you
                    personally behold.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="letter">
        <div className="container">
          <h2 className="text-center">Letter from the President</h2>
          <div className="letter-from-the-president">
            <p>
              Welcome to the RRGC website. The RRGC was established in 1955. We
              are a multi-faceted Club comprised of sportsmen and sportswomen.
            </p>
            <p>
              Our object is to encourage sport fishing, pistol, rifle, archery,
              gun shooting, photography, and related out door activities.
            </p>
            <p>
              We currently operate a 10 meter indoor airgun range and a 18 meter
              indoor archery range.
            </p>
            <p>
              We offer a friendly, safe, supervised, controlled environment for
              members and drop in to practice and hone their skills.
            </p>
            <p>
              We have qualified Range officers and instructors. If you are a
              beginner or advanced shooter or archer we have a venue that
              welcomes you.
            </p>
            <p>
              Please check out our different divisions, many members enjoy more
              than one activity that our club offers.
            </p>
            <p>
              Regards,
              <br />
              <br />
              Mike Thorne
              <br />
              President,
              <br />
              RRGC.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
