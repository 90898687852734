import React, { useEffect } from 'react';

const SuperSaaSWidget = () => {
    useEffect(() => {
        const options = {
            widget_type: 'frame',
            view: 'card',
            width: '100%',
            height: '500px',
            menu: 'show'
        };

        const script = document.createElement("script");
        script.src = "https://cdn.supersaas.net/widget.js";
        script.async = true;

        script.onload = () => {
            const script2 = document.createElement("script");
            script2.innerHTML = `var supersaas = new SuperSaaS("535955:RRGC-Archery","718884:Bookings", ${JSON.stringify(options)});`;
            document.getElementById('supersaas-widget').appendChild(script2); // Append to supersaas-widget element
        };

        document.body.appendChild(script);

        return () => {
            // Clean up SuperSaaS widget if needed
        };
    }, []);

    return (
        <div id="supersaas-widget"></div>
    );
};

export default SuperSaaSWidget;
