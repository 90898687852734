import React from 'react';
import RegistrationComponent from '../../components/registration/registration';

const ArcheryRegistration = () => {
  const breakingNews = '';
  const heroImage = '/public/images/archery-hero-min.jpg';
  return (
    <div className="archery page">
      <div className="page-banner" style={{ backgroundImage: `url(${heroImage})` }}>
        <div className="page-banner-text">
          <div className="is-operational">
            <h1>RRGC Archery Registration</h1>
          </div>
        </div>
      </div>
      {breakingNews && (
        <section className="breaking-news">
          <div className="container">
            <h3>{breakingNews}</h3>
          </div>
        </section>
      )}
      <section className="container">
        <RegistrationComponent section={'archery'} />
      </section>
    </div>
  );
};

export default ArcheryRegistration;
