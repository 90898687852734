import React from "react";
import ReactGA from "react-ga";
import moment from "moment";
import AntiBot, { openTo } from "../../components/AntiBot";
import { triggerDownload } from "../../services/firebase";

const DEFAULT_FORMAT = "MM/DD/YYYY HH:mm";
const REG_CLOSED = moment("October 25, 2023 11:59pm", "MMMM D, YYYY h:ma");

const GHHAMatch = () => {
  const heroImage = "/public/images/airgun-hero-min.jpg";
  const relays = {
    "A Relay": [
      { date: new Date("11/4/2023"), hours: ["9:00am", "1:45pm"] },
      { date: new Date("11/5/2023"), hours: ["9:00am"] },
    ],
    "B Relay": [
      { date: new Date("11/4/2023"), hours: ["11:15am", "4:00pm"] },
      { date: new Date("11/5/2023"), hours: ["11:15am"] },
    ],
  };

  const schedule = {
    "Range Setup": {
      date: new Date("11/3/2023"),
      from: "5:00pm",
      to: "7:00pm",
    },
    "Open Practice": {
      date: new Date("11/3/2023"),
      from: "7:30pm",
      to: "9:00pm",
    },
  };

  const matchday = {
    "Registration & Equipment Check": {
      date: new Date("11/4/2023"),
      from: "7:30am",
      to: "9:00am",
    },
  };

  const awards = {
    date: moment("11/05/2023 14:30", DEFAULT_FORMAT),
  };

  const downloadMatchInfo = () => {
    ReactGA.event({
      category: "airgun",
      action: "Downloaded Match Info",
    });
    triggerDownload("public/gh_rules_23.pdf");
  };

  const downloadMatchRegistration = () => {
    ReactGA.event({
      category: "airgun",
      action: "Downloaded Match Registration",
    });
    triggerDownload("public/gh_registration_23.pdf");
  };

  const registrationsOpen = moment().diff(REG_CLOSED) < 0;

  return (
    <div className="airgun page">
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="page-banner-text">
          <div className="is-operational">
            <p>The Richmond Rod and Gun Club</p>
            <h1>George Hutcheon Hibernation Airgun Match</h1>
            <p>November 4, 2023 - November 5, 2023</p>
          </div>
        </div>
      </div>
      <section className={`breaking-news ${registrationsOpen ? "happy" : ""}`}>
        <div className="container">
          <h3>Registrations {registrationsOpen ? "Open" : "Closed"}</h3>
        </div>
      </section>

      <div className="introduction-block container">
        <div className="left-block">
          <div className="location-block">
            <a href="https://g.page/rrgcarcheryandairgun?share" target="_blank">
              <div className="location-icon-container">
                <i className="fa fa-map-marker-alt"></i>
              </div>
              <div className="location-info-container">
                <p>
                  <span className="heading">Archery & Airgun Range</span>
                  <br />
                  7400 River Rd Suite 140
                  <br />
                  Richmond, BC,
                  <br />
                  Canada
                </p>
                <p className="directions">
                  Directions <i className="fa fa-caret-right"></i>
                </p>
              </div>
            </a>
          </div>
          <div className="hours text-center" style={{ paddingLeft: "30px" }}>
            <h2>Match Schedule</h2>
            <p>
              <strong>"Athletes-to-Line Times"</strong>
            </p>
            {Object.keys(relays).map((relay) => {
              return (
                <div className="relay">
                  <div className="relay-name">{relay}</div>
                  <div className="relay-schedule">
                    {relays[relay].map((dayObject) => {
                      return (
                        <div className="relay-day">
                          <div className="relay-day-string">
                            <strong>
                              {moment(dayObject.date).format("dddd")}
                            </strong>
                            <br />
                            {moment(dayObject.date).format("MMMM Do, YYYY")}
                          </div>
                          <div className="relay-time">
                            {dayObject.hours.map((time) => {
                              return <div className="relay-time">{time}</div>;
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="relay"></div>
          </div>
        </div>
        <div className="right-content">
          <section style={{ marginBottom: "60px" }}>
            <h2>The George Hutcheon Hibernation Airgun Match</h2>
            {registrationsOpen ? (
              <>
                <p>Registrations Open!</p>
                <div className="register-cta">
                  <a
                    href="javascript:void(0)"
                    onClick={downloadMatchRegistration}
                  >
                    <span>Download Registration Form &nbsp;</span>
                    <span>
                      <i className="fas fa-caret-right"></i>
                    </span>
                  </a>
                </div>
              </>
            ) : (
              <h3>Registrations are now closed.</h3>
            )}
          </section>
          <section>
            <p>
              <strong>Place: &nbsp;</strong>
              Richmond Rod and Gun Club 7400 River Road Richmond B.C. (Southwest
              of #3 Rd & Cambie Rd)
            </p>

            <p>
              <strong>Eligibility: &nbsp;</strong>
              Open to all competitors. Membership in B.C.T.S.A. and S.F.C. or
              their respective Provincial or National organization is{" "}
              <strong>REQUIRED</strong>. Match is limited to a total of 30
              shooters only, 15 shooters per relay.
            </p>

            <p>
              <strong>Course of Fire: &nbsp;</strong>
              Match consists of 3 complete courses of fire for an aggregate
              match score. 90 minutes for open classes, Ladies and Junior
              competitors on returning targets. A 15 Minute preparation time and
              sighting time starts before the match.
            </p>

            <p>
              <strong>Classification: &nbsp;</strong>
              Competitors will be classified according to their SFC shooting
              cards. Those unable to produce a valid card will compete in the
              Expert Class.
            </p>

            <p>
              <strong>Rules: &nbsp;</strong>
              Standard ISSF Rules will apply with the use of paper targets
              <ul>
                <li>Pistol will shoot 2 shots per target</li>
                <li>Rifle will shoot 1 shot per target</li>
              </ul>
            </p>
            <p>
              <strong>Dress Code: </strong>
              Athletes must wear athletic clothing. No jeans, khakis, shorts, or
              sweat apparel are permitted. NO brands (examples: Nike, Roots,
              Adidas, etc.). Pistol shooters are PROHIBITED to wear high tops
              during relays.
            </p>
            <p>
              <strong>
                Failure to abide by ISSF Rules will be disqualified.
              </strong>
            </p>
            <p>
              <strong>Airguns: &nbsp;</strong>
              All Airguns must meet ISSF specifications. Airguns will be subject
              to inspection. The same airgun must be used for all 3 courses of
              fire in order to qualify for the aggregate score unless it becomes
              disabled and then may be replaced with approval of the Chief Range
              Officer.
            </p>
          </section>
          <section>
            <h2>Schedule</h2>
            {Object.keys(schedule).map((event) => {
              const ev = schedule[event];
              return (
                <div className="half-half">
                  <div className="half-inner">
                    <h3 className="no-margin-bottom">{event}</h3>
                  </div>
                  <div className="half-inner">
                    <p>
                      {moment(ev.date).format("MMMM Do, YYYY")}
                      <br />
                      {ev.from} - {ev.to}
                    </p>
                  </div>
                </div>
              );
            })}
          </section>
          <section>
            <h2>Match Day</h2>
            {Object.keys(matchday).map((event) => {
              const ev = matchday[event];
              return (
                <div className="half-half">
                  <div className="half-inner">
                    <h3 className="no-margin-bottom">{event}</h3>
                  </div>
                  <div className="half-inner">
                    <p>
                      {moment(ev.date).format("MMMM Do, YYYY")}
                      <br />
                      {ev.from} - {ev.to}
                    </p>
                  </div>
                </div>
              );
            })}
            {Object.keys(relays).map((relay) => {
              const ev = relays[relay];
              return (
                <p>
                  <div className="half-half">
                    <div className="half-inner">
                      <h3
                        className="no-margin-bottom"
                        style={{ paddingLeft: "40px" }}
                      >
                        {relay}
                      </h3>
                    </div>
                    <div className="half-inner">
                      {ev.map((segment) => {
                        return (
                          <p>
                            <span style={{ textDecoration: "underline" }}>
                              {moment(segment.date).format("MMMM Do, YYYY")}
                            </span>
                            <br />
                            {segment.hours.join(", ")}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </p>
              );
            })}
            <p>
              These are “Athletes to the line” times, then there will be a
              15-minute preparation time, then match start.
            </p>
          </section>
          <section>
            <h2>Awards</h2>
            <p>
              <strong>
                {moment(awards.date).format("MMMM Do, YYYY | h:ma")}
              </strong>
            </p>
            <p>Awards are to be awarded immediately after range tear down</p>
          </section>
          <section>
            <p>
              <a href="javascript:void(0)" onClick={downloadMatchInfo}>
                Click here
              </a>{" "}
              to download this page for your records.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export { REG_CLOSED };
export default GHHAMatch;
