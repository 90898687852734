import React from "react";
import ReactGA from "react-ga";
import AntiBot, { openTo } from "../../components/AntiBot";
import { triggerDownload } from "../../services/firebase";

const RRGC_DOMAIN = "richmondrodandgunclub.com";

const JOP = () => {
  const heroImage = "/public/images/jop-min.jpg";
  const today = new Date();
  const day = today.getDay();
  const hours = [
    { hours: "9:00AM - 10:00AM" },
    { hours: "10:30AM - 11:30AM" },
    { hours: "12:00PM - 1:00PM" },
    { hours: "1:30PM - 2:30PM" },
    { hours: "3:00PM - 4:00PM" },
    { hours: "7:00PM - 8:45PM" },
  ];
  const team = [
    {
      title: "Head Coach",
      name: "Alex Desemery",
      email: RRGC_DOMAIN.split("")
        .reverse()
        .concat(["@", "d", "x", "e", "l", "a"]),
      phone: ["5", "0", "7", "2", ".", "3", "6", "3", ".", "4", "0", "6"],
    },
    {
      title: "JOP Coordinator & Coach",
      name: "Kate Young",
      email: [
        "m",
        "o",
        "c",
        ".",
        "l",
        "i",
        "a",
        "m",
        "g",
        "@",
        "p",
        "o",
        "j",
        ".",
        "c",
        "g",
        "r",
        "r",
      ],
      phone: ["4", "4", "4", "3", ".", "2", "6", "5", ".", "4", "0", "6"],
    },
  ];

  const downloadJopInfo = () => {
    ReactGA.event({
      category: "JOP",
      action: "Downloaded JOP Info",
    });
    triggerDownload("public/2020-09-13 Junior Olympic Program.docx");
  };

  return (
    <div className="archery page">
      <div
        className="page-banner"
        style={{ backgroundImage: `url(${heroImage})` }}
      >
        <div className="page-banner-text">
          <div className="is-operational">
            <p>The Richmond Rod and Gun Club</p>
            <h1>JUNIOR OLYMPIAN PROGRAM</h1>
            {/* <p>September 19, 2020 - June 5, 2021</p> */}
          </div>
        </div>
      </div>

      <div className="introduction-block">
        <div className="left-block">
          <div className="location-block">
            <a href="https://g.page/rrgcarcheryandairgun?share" target="_blank">
              <div className="location-icon-container">
                <i className="fa fa-map-marker-alt"></i>
              </div>
              <div className="location-info-container">
                <p>
                  <span className="heading">Archery & Airgun Range</span>
                  <br />
                  7400 River Rd Suite 140
                  <br />
                  Richmond, BC,
                  <br />
                  Canada
                </p>
                <p className="directions">
                  Directions <i className="fa fa-caret-right"></i>
                </p>
              </div>
            </a>
          </div>
          <div className="hours text-center" style={{ paddingLeft: "30px" }}>
            <h2>Class Hours</h2>
            <p>
              <strong>Saturdays</strong>
            </p>
            {hours.map((entry) => (
              <div className="text-center hours-day">
                <div className="opening-hours">{entry.hours}</div>
              </div>
            ))}
            <p style={{ color: day === 6 ? "green" : "grey" }}>
              {day === 6 ? "Open Today" : "Opens on Saturdays"}
            </p>
          </div>
        </div>
        <div className="right-content">
          <h2>
            Welcome to the Richmond Rod and Gun Clubs' Junior Olympian Program.
          </h2>
          <section>
            <h3>Meet the Team</h3>
            <div className="jop-cards">
              {team.map(({ title, name, email, phone }) => {
                return (
                  <div className="jop-card">
                    <h4>{name}</h4>
                    <p>
                      <span className="icon-jop">
                        <i className="fas fa-id-card-alt"></i>
                      </span>
                      {title}
                      <br />
                      <span className="icon-jop">
                        <i className="fas fa-envelope"></i>
                      </span>
                      <a
                        href="javascript:void(0)"
                        onClick={openTo(email, "mailto")}
                      >
                        <AntiBot text={email} />
                      </a>
                      <br />
                      <span className="icon-jop">
                        <i className="fas fa-mobile-alt"></i>
                      </span>
                      <a
                        href="javascript:void(0)"
                        onClick={openTo(phone, "tel")}
                      >
                        <AntiBot text={phone} />
                      </a>
                    </p>
                  </div>
                );
              })}
            </div>
          </section>
          <section>
            <p>To participate in this program the athlete must:</p>

            <ul>
              <li>
                Be a current member of the{" "}
                <a href="https://bcarchery.ca/about/membership/join-bc-archery-association">
                  BC Archery Association
                </a>{" "}
                ($65)
              </li>
              <li>
                Hold a current{" "}
                <a href="/membership">Richmond Rod and Gun Club Membership</a>.
                Membership card must be presented before each session.
              </li>
            </ul>

            <p>
              We would encourage athletes to commit to attending a specific time
              slot on a regular basis.
            </p>
            {/* <p>
              <a href="javascript:void(0)" onClick={downloadJopInfo}>
                Click here
              </a>{" "}
              to download this page for your records.
            </p> */}
            <p className="disclaimer" style={{ margin: 0 }}>
              Payment(s) must be completed online and in advance of each
              session.
              <br />
              The RRGC Covid protocol does not permit cash transactions at the
              range.
              <br />
              A total of 7 individuals are permitted in the range per session; 5
              archers, 1 coach and 1 parent or range officer.
              <br />
              <br />
              We appreciate help with sanitizing the range at the end of each
              session.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default JOP;
