import { initializeApp } from "firebase/app";
import {
  getStorage,
  getDownloadURL,
  ref as storageRef,
} from "firebase/storage";
import { getDatabase, ref, child, get } from "firebase/database";
import { DATE_KEY_FORMAT } from "./constants";
import moment from "moment";

const config = {
  apiKey: "AIzaSyDsQQMEqFDehGLyxSz3-0rpUkZ5phtedGI",
  authDomain: "rrgc-a26aa.firebaseapp.com",
  databaseURL: "https://rrgc-a26aa.firebaseio.com",
  projectId: "rrgc-a26aa",
  storageBucket: "rrgc-a26aa.appspot.com",
  messagingSenderId: "354906600098",
  appId: "1:354906600098:web:0343155f1bdb5285589dfb",
  measurementId: "G-42Q2EHNF1X",
};

initializeApp(config);

export const triggerDownload = (id) => {
  const storage = getStorage();
  getDownloadURL(storageRef(storage, id)).then((url) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.href = url;
    link.click();
  });
};

const SPECIAL_CLOSURES_REF =
  "102oZUQqOeTAl-ApyNu3Kk3iHJ0FhOTeYYHcTJOUfkDc/Sheet1";
const BOOKINGS_REF = "bookings";

const getSpecialClosures = async () => {
  const dbRef = ref(getDatabase());
  const snapshot = await get(child(dbRef, SPECIAL_CLOSURES_REF));
  if (snapshot.exists()) {
    const raw = snapshot.val();
    console.log(raw);
    return Object.keys(raw).reduce((acc, key) => {
      const date = moment(key).format(DATE_KEY_FORMAT);
      acc[date] = raw[key];
      return acc;
    }, {});
  } else {
    return;
  }
};

export { getSpecialClosures };
